



































































































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import md5 from 'js-md5';
import { UserRegister } from '@/http/api/LoginApi';

interface IuserInfo {
  creditCode: string | object[];
  unitName: string | object[];
  contactsName: string | object[];
  contactsPhone: string | object[];
  contactsEmail: string | object[];
  password: string | object[];
  passwords: string | object[];
}
interface IuserInfoaA {
  phone: string | object[];
  name: string | object[];
  identity: string | object[];
  unit: string | object[];
  duty: string | object[];
  password: string | object[];
  passwords: string | object[];
}


@Component
export default class Register extends Vue {
  @Ref() private unitRegisterfrom!: HTMLDListElement;
  @Ref() private personageRegisterfrom!: HTMLDListElement;

  private loading: boolean = false;
  private unitUserInfo: IuserInfo = {
    creditCode: '',
    unitName: '',
    contactsName: '',
    contactsPhone: '',
    contactsEmail: '',
    password: '',
    passwords: '',
  };
  private personageUserInfo: IuserInfoaA = {
    phone: '',
    name: '',
    identity: '',
    unit: '',
    duty: '',
    password: '',
    passwords: '',
  };
  private unitRules: IuserInfo = {
    creditCode: [
      { required: true, message: '请输入账号', trigger: 'blur' },
      {pattern:/[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/,message:'数字和大写字母组合，不包括I、O、Z、S、V字母'}
    ],
    unitName: [
      { required: true, message: '请输入单位全称', trigger: 'blur' }
    ],
    contactsName: [
      { required: true, message: '请输入常用联系人', trigger: 'blur' },
      { pattern:/^[\u4e00-\u9fa5]{2,4}$/,message:'2-4个汉字'}
    ],
    contactsPhone: [
      { required: true, message: '请输入联系人手机号', trigger: 'blur' },
      { pattern: /^1[3456789]\d{9}$/, message: '联系人手机号格式有误', trigger: 'blur' },
    ],
    contactsEmail: [
      { required: true, message: '请输入联系人邮箱', trigger: 'blur' },
      {pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,message: '联系人邮箱格式有误',trigger: 'blur'},
      {max: 50,message: '邮箱不得超过50字符',},
    ],
    password: [
      { required: true, message: '请输入密码', trigger: 'blur' },
      { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/, message: '8-16位数字字母组合', trigger: 'blur' },
    ],
    passwords: [
      { required: true, message: '请重复输入密码', trigger: 'blur' },
      { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/, message: '8-16位数字字母组合', trigger: 'blur' },
    ],
  };
  private personageRules: IuserInfoaA = {
    phone: [
      { required: true, message: '请输入手机号', trigger: 'blur' },
      { pattern: /^1[3456789]\d{9}$/, message: '联系人手机号格式有误', trigger: 'blur' },
    ],
    name: [
      { required: true, message: '请输入姓名', trigger: 'blur' },
      { pattern:/^[\u4e00-\u9fa5]{2,4}$/,message:'2-4个汉字'}
    ],
    identity: [
      { required: true, message: '请输入身份证', trigger: 'blur' },
      { pattern: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '身份证格式有误' },
    ],
    unit: [
      { required: true, message: '请输入单位', trigger: 'blur' },
      { pattern:/^.{2,50}$/, message:'2-50个汉字'}
    ],
    duty: [
      { required: true, message: '请输入职务', trigger: 'blur' },
      { pattern:/^.{2,50}$/,message:'2-50个汉字'}
    ],
    password: [
      { required: true, message: '请输入密码', trigger: 'blur' },
      { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/, message: '8-16位数字字母组合', trigger: 'blur' },
    ],
    passwords: [
      { required: true, message: '请重复输入密码', trigger: 'blur' },
      { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/, message: '8-16位数字字母组合', trigger: 'blur' },
    ],
  };
  private registerSucceedDialog: any = {
    show: false,
  };
  private tabPaneVal: any = 'unit';
  // 返回登录
  private hanldeBack(): void {
    this.$router.push({ path: '/login' });
  }
  // 注册
  private registerSubmit(flag: number): void {
    // 3.企业用户 4.个人用户
    if (flag === 3) {
      (this.unitRegisterfrom as any).validate((valid: boolean) => {
        if (valid) {
          if (this.unitUserInfo.password !== this.unitUserInfo.passwords) {
            this.$message.warning('密码和确认密码要保持一致');
          } else {
            this.requestFun(flag);
          }
        }
      });
    } else {
      (this.personageRegisterfrom as any).validate((valid: boolean) => {
        if (valid) {
          if (this.personageUserInfo.password !== this.personageUserInfo.passwords) {
            this.$message.warning('密码和确认密码要保持一致');
          } else {
            this.requestFun(flag);
          }
        }
      });
    }
  }
  private requestFun(flag: number) {
    this.loading = true;
    const parmas: any = {
      userType: flag,
    };
    if (flag === 3) {
      parmas.loginName = this.unitUserInfo.creditCode;
      parmas.unitName = this.unitUserInfo.unitName;
      parmas.userName = this.unitUserInfo.contactsName;
      parmas.mobile = this.unitUserInfo.contactsPhone;
      parmas.email = this.unitUserInfo.contactsEmail;
      parmas.password = md5(this.unitUserInfo.password);
    } else {
      parmas.loginName = this.personageUserInfo.phone;
      parmas.userName = this.personageUserInfo.name;
      parmas.idCard = this.personageUserInfo.identity;
      parmas.unitName = this.personageUserInfo.unit;
      parmas.position = this.personageUserInfo.duty;
      parmas.mobile = this.personageUserInfo.phone;
      parmas.password = md5(this.personageUserInfo.password);
    }
    UserRegister(parmas).then((res) => {
      this.loading = false;
      if (res.code === '000') {
        this.registerSucceedDialog.show = true;
      }
    });
  }
  private created() {
    this.tabPaneVal = this.$route.query.type || 'unit'
  }
}
